import React, { useState, useEffect } from "react";

import ParticipantsList from "./participants";
import Room from "./room";
import Solicitation from "../solicitation";
import Waiting from "../waiting";

//service
import { alreadyMatched } from "../../utils/services/participants";

import { useTranslation } from "react-i18next";

export default function Participants() {
  const [step, setStep] = useState("");
  const [participant, setParticipant] = useState();
  const [userName, setUserName] = useState();
  const [userData, setUserData] = useState();
  const [idRequest, setIdRequest] = useState();

  // add translation
  const { t, i18n } = useTranslation()
  const [isPtBr, setIsPtBr] = useState(true)

  async function getUser() {
    if (window.location.search != "") {
      // var user = window.location.search;
      const params = new URLSearchParams(window.location.search);

      // var data = user.split("?");
      await alreadyMatched({
        // guestId: Number(data[1].replace("id=", "")),
        guestId: Number(params.get('id')),
      })
        .then((response) => {
          if (!response.data[0].accepted) {
            if (
              response.data[0].chooser.id === Number(params.get('id'))
            ) {
              setUserData(response.data[0].chosen);
              setStep("_d");
            } else {
              setUserData(response.data[0].chooser);
              setStep("_c");
            }
            setIdRequest(response.data[0].id);
            return
          }
          if (response.status === 200) {
            if (
              response.data[0].chooser.id === Number(params.get('id'))
            ) {
              setUserName(response.data[0].chosen.name);
            } else setUserName(response.data[0].chooser.name);

            setStep("_b");
          } else setStep("_a");
        })
        .catch((error) => {
          setStep("_a");
        });
    }
  }

  useEffect(() => {
    getUser();
    setLanguage()
    setIsPtBr(i18n.language === "pt-br")
  }, []);

  function setLanguage() {
    // let url = window.location.search;
    // const urlTransform = url.substring(1, url.length).replace(/\?/g, '&')
    const params = new URLSearchParams(window.location.search);
    let idiom = params.get('idiom');
    i18n.changeLanguage(idiom || "pt-br")
  }
  const translate = text => !isPtBr ? `${text}/${t(`${text}`)}` : text
  return (
    <div>
      {step === "_a" && (
        <ParticipantsList setStep={setStep} setUserData={setUserData} translate={translate} idiom={i18n.language} />
      )}
      {step === "_b" && <Room participant={participant} userName={userName} translate={translate} idiom={i18n.language} />}
      {step === "_c" && (
        <Solicitation
          setStep={setStep}
          setParticipant={setParticipant}
          userData={userData}
          idRequest={idRequest}
          translate={translate}
          idiom={i18n.language}
        />
      )}
      {step === "_d" && (
        <Waiting
          setStep={setStep}
          setParticipant={setParticipant}
          userData={userData}
          idRequest={idRequest}
          translate={translate}
          idiom={i18n.language}
        />
      )}
    </div>
  );
}
