import { BrowserRouter, Routes, Route } from "react-router-dom";
//styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";
//components
import Participants from "./pages/participants";
import Report from "./pages/report";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/participants" element={<Participants />} />
        <Route path="/report" element={<Report />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
