import React, { useEffect, useState } from "react";

import { Card, CardBody, CardTitle, Col, Row, Button } from "reactstrap";

//service
import { requestRoommate } from "../../utils/services/participants";

export default function Room(props) {
  const { translate, idiom } = props
  const [user, setUser] = useState();

  async function requestFunc(e) {
    await requestRoommate({
      id: props.idRequest,
      accepted: e,
      idiom: idiom,
    })
      .then((response) => {
        console.log("response ok", response);
        window.location.reload();
      })
      .catch((error) => {
        console.log("response error", error);
      });
  }

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#34c38f",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <CardBody style={{ margin: 30 }}>
          <CardTitle style={{ fontSize: 20 }}>
            {translate("Você foi escolhido como dupla de quarto por:")} {props.userData.name} -{" "}
            {props.userData.email}
          </CardTitle>
          <div
            style={{ fontSize: 18, display: "flex", flexDirection: "column" }}
          >
            {/* <a style={{ cursor: "default" }}>
              Você foi escolhido como dupla de quarto por: {props.userData.name}{" "}
              - {props.userData.email}
            </a> */}
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px 0",
                alignItems: "center",
              }}
            >
              <p>{translate("Clique aqui para confirmar:")}</p>
              <div>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={() => requestFunc(true)}
                >
                  {translate("Aceitar")}
                </Button>
                <Button
                  color="danger"
                  className="btn btn-success waves-effect waves-light"
                  style={{ marginLeft: 10 }}
                  onClick={() => requestFunc(false)}
                >
                  {translate("Negar")}
                </Button>
              </div>
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
