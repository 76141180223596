import axios from "axios";

const baseUrl = "https://us-central1-inteegra-ping.cloudfunctions.net";

function getReport({ eventId }) {
  var config = {
    method: "get",
    url: `${baseUrl}/roommatesreport?eventid=${eventId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteMatch({ id }) {
  var config = {
    method: "delete",
    url: `${baseUrl}/roommates?id=${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getParticipants({ eventId, page, size, search, groupId }) {
  const currentIndex = page === 0 ? 1 : page;
  var config = {
    method: "get",
    url: `${baseUrl}/roommates?eventid=${eventId}&page=${currentIndex}&size=${size}&search=${search}&groupId=${groupId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function alreadyMatched({ guestId }) {
  var config = {
    method: "get",
    url: `${baseUrl}/roommates?guestid=${guestId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

function createRoom({
  id,
  name,
  email,
  groupId,
  idChosen,
  nameChosen,
  emailChosen,
  groupIdChoosen,
  eventId,
  idiom
}) {
  var dataForm = {
    chooser: {
      id: id,
      name: name,
      email: email,
      group: groupId
    },
    chosen: {
      id: idChosen,
      name: nameChosen,
      email: emailChosen,
      group: groupIdChoosen
    },
    eventId: eventId,
    idiom: idiom
  };

  var config = {
    method: "post",
    url: `${baseUrl}/roommates`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function requestRoommate({ id, accepted, idiom }) {
  var dataForm = {
    id: id,
    accepted: accepted,
    idiom: idiom
  };

  var config = {
    method: "put",
    url: `${baseUrl}/roommates`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };

  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  deleteMatch,
  getReport,
  getParticipants,
  alreadyMatched,
  createRoom,
  requestRoommate,
};
