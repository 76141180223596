import React, { useEffect, useState } from "react";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

export default function Waiting(props) {
  const { translate } = props
  const [user, setUser] = useState();

  useEffect(() => {
    if (props.participant) {
      setUser(props.participant.name);
    } else {
      setUser(props.userName);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#34c38f",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <CardBody style={{ margin: 30 }}>
          <CardTitle style={{ fontSize: 20 }}>
            {translate("Você selecionou:")} {props.userData?.name} - {props.userData?.email}{" "}
            {translate("como dupla de quarto. Por favor aguarde confirmação.")}
          </CardTitle>
          <div
            style={{ fontSize: 18, display: "flex", flexDirection: "column" }}
          >
            {/* <a
              style={{ cursor: "default" }}
              onClick={() => console.log(props.userData)}
            >
              Esperando!{" "}
              <i
                className="mdi mdi-check-circle-outline"
                style={{ color: "#34c38f" }}
              />
            </a> */}
            {/* <p style={{ cursor: "default" }}>
              Você selecionou: {props.userData?.name} - {props.userData?.email}{" "}
              como dupla de quarto. Por favor aguarde confirmação.
            </p> */}
            {/* <a style={{ cursor: "default" }}>
              Dúvidas entre em contato com Grupo MM -
              aline.nascimento@grupommeventos.com
            </a> */}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
