import React, { useEffect, useState } from "react";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

export default function Room(props) {
  const { translate } = props
  const [user, setUser] = useState();

  useEffect(() => {
    if (props.participant) {
      setUser(props.participant.name);
    } else {
      setUser(props.userName);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#34c38f",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <CardBody style={{ margin: 30 }}>
          <CardTitle style={{ fontSize: 20 }}>
            {translate("Selecionado!")}{" "}
            <i
              className="mdi mdi-check-circle-outline"
              style={{ color: "#34c38f" }}
            />
          </CardTitle>
          <div
            style={{ fontSize: 18, display: "flex", flexDirection: "column" }}
          >
            {/* <p style={{ cursor: "default" }}>
              Selecionado!{" "}
              <i
                className="mdi mdi-check-circle-outline"
                style={{ color: "#34c38f" }}
              />
            </p> */}
            <p style={{ cursor: "default" }}>
              {translate("Sua dupla de quarto é:")} {user ? user : "fulano"}
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
