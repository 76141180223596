import React, { useState, useEffect } from "react";

import { Table, Col, Row, Card, CardBody, CardTitle, Button } from "reactstrap";
//componente de paginação
import Pagination from "../../components/Pagination";
//services
import {
  getParticipants,
  createRoom,
  alreadyMatched,
} from "../../utils/services/participants";

import SweetAlert from "react-bootstrap-sweetalert";

//criptografia
import base64 from "react-native-base64";

import { isMobile } from "react-device-detect";

export default function Participants(props) {
  const { translate, idiom } = props
  const [search, setSearch] = useState("");
  const [participants, setParticipants] = useState([]);
  const [user, setUser] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  //confirmação
  const [participantSelected, setParticipantSelected] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [success_dlgMe, setsuccess_dlgMe] = useState(false);
  const [nextFunc, setNextFunc] = useState(false);

  async function selectParticipant(e) {
    await createRoom({
      id: user.id,
      name: user.name.replace(/%20/g, " "),
      email: user.email,
      groupId: Number(user?.groupId) || null,
      idChosen: e.id,
      nameChosen: e.name,
      emailChosen: e.email,
      groupIdChoosen: e?.groupId || null,
      eventId: user.eventId,
      idiom: idiom,
      // eventId: e.eventId,
    })
      .then((response) => {
        if (response.status === 201) {
          props.setUserData(e);
          props.setStep("_d");
        }
      })
      .catch((error) => console.log("function error", error));
  }

  async function verifyParticipant(e) {
    await alreadyMatched({
      guestId: e.id,
    })
      .then((response) => {
        if (response.status === 404) {
          autoCheck(e);
          // setParticipantSelected(e);
          // setconfirm_alert(true);
        }

        if (response.status === 200) {
          setsuccess_dlg(true);
        }
      })
      .catch((error) => {
        console.log("response error", error);
      });
  }

  async function autoCheck(e) {
    await alreadyMatched({
      guestId: user.id,
    })
      .then((response) => {
        if (response.status === 404) {
          setParticipantSelected(e);
          setconfirm_alert(true);
        }

        if (response.status === 200) {
          setsuccess_dlgMe(true);
        }
      })
      .catch((error) => {
        console.log("response error", error);
      });
  }

  async function getAllParticipant() {
    await getParticipants({
      eventId: user.eventId,
      // eventId: 87491,
      // eventId: 67710,
      page: currentIndex,
      size: itemsPerPage,
      search: search,
      groupId: user?.groupId
    })
      .then((response) => {
        setParticipants(response.data.guests);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => console.log("function error", error));
  }

  function getUser(e) {
    if (window.location.search !== "") {
      const params = new URLSearchParams(window.location.search);

      setUser({
        id: Number(params.get('id')),
        name: params.get('name'),
        email: params.get('email').replace(' ', '+'),
        eventId: base64.decode(params.get('eventId')),
        groupId: params.get('group') || null
      });
      setNextFunc(true);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (nextFunc) {
      getAllParticipant();
    }
  }, [currentIndex, itemsPerPage, search, user, nextFunc]);

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0 0 0",
      }}
    >
      {confirm_alert ? (
        <SweetAlert
          title={`${translate("Deseja selecionar")} ${participantSelected.name} ?`}
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            selectParticipant(participantSelected);
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          {translate("ATENÇÃO! Essa ação não poderá ser desfeita")}
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          danger
          title={translate("Esse participante não está mais disponível")}
          onConfirm={() => {
            getAllParticipant();
            setsuccess_dlg(false);
          }}
        ></SweetAlert>
      ) : null}
      {success_dlgMe ? (
        <SweetAlert
          danger
          title={translate("Você tem uma solicitação pendente")}
          onConfirm={() => {
            getAllParticipant();
            setsuccess_dlgMe(false);
            window.location.reload();
          }}
        ></SweetAlert>
      ) : null}
      <Col md={10} style={{ margin: 0, padding: 0 }}>
        <Card>
          <CardBody>
            <CardTitle
              onClick={() => console.log(user.name.replace(/%20/g, " "))}
            >
              {translate("ESCOLHA SEU PARCEIRO DE QUARTO")}
            </CardTitle>
            <Col md={5}>
              <div className="app-search d-lg-block events-input-search">
                <label className="form-label">
                  {/* Escolha seu colega de quarto */}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={translate("Pesquise o convidado")}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Col>
            <div className="table-responsive">
              {!isMobile ? (
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{translate("Convidados")}</th>
                      <th>Email</th>
                      <th>{translate("Opção")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants.length > 0 &&
                      participants
                        .filter(
                          (e) =>
                            e.name
                              .toLocaleLowerCase()
                              .indexOf(search.toLocaleLowerCase()) > -1
                        )
                      .filter((e) => e.id != user.id)
                        .map((e, i) => (
                          <tr key={i}>
                            <td onClick={() => console.log(e)}>{e.name}</td>
                            <td>{e.email}</td>
                            <td>
                              <Button
                                color="success"
                                className="btn btn-success waves-effect waves-light"
                                onClick={() => {
                                  verifyParticipant(e);
                                }}
                              >
                                {translate("Selecionar")}
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              ) : (
                <Table className="table mb-0 table-sm table-striped">
                  <thead className="table-light">
                    <tr>
                        <th>{translate("Opção")}</th>
                      <th onClick={() => console.log(participants)}>
                          {translate("Convidados")}
                      </th>
                        <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants.length > 0 &&
                      participants
                        .filter(
                          (e) =>
                            e.name
                              .toLocaleLowerCase()
                              .indexOf(search.toLocaleLowerCase()) > -1
                        )
                        .filter((e) => e.id != user.id)
                        .map((e, i) => (
                          <tr key={i}>
                            <td>
                              <Button
                                color="success"
                                className="btn btn-success waves-effect waves-light"
                                onClick={() => {
                                  verifyParticipant(e);
                                }}
                                style={{ fontSize: "10px" }}
                              >
                                {translate("Selecionar")}
                              </Button>
                            </td>
                            <td
                              style={{ fontSize: "10px" }}
                              onClick={() => console.log(e)}
                            >
                              {e.name}
                            </td>
                            <td style={{ fontSize: "10px" }}>{e.email}</td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
            </div>
          </CardBody>
        </Card>
        {/* 
      Fornecer os 4 parametros obrigatórios;
      ** data - a array contendo as informações da tabela
      ** search - o state do termo de pesquisa
      ** currentIndex - posição atual do index
      ** setCurrentIndex - função para atualizar a posição atual do index
      */}
        <Pagination
          data={participants}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={totalPages * itemsPerPage}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={5}
          labelItensPerPage={translate("Itens por página")}
        />
      </Col>
    </Row>
  );
}
