import React, { useState, useEffect } from "react";

import moment from "moment";
import exportFromJSON from "export-from-json";

import { Table, Col, Row, Card, CardBody, CardTitle, Button } from "reactstrap";

//criptografia
import base64 from "react-native-base64";

//services
import { getReport, deleteMatch } from "../../utils/services/participants";

import SweetAlert from "react-bootstrap-sweetalert";

export default function Report(props) {
  const [search, setSearch] = useState("");
  const [report, setReport] = useState([]);
  const [eventId, setEventId] = useState();
  const [nextFunc, setNextFunc] = useState(false);
  const [onAlert, setOnAlert] = useState(false);
  const [onAlertSuccess, setOnAlertSuccess] = useState(false);

  const [selectedToDelete, setSelectedToDelete] = useState("");

  const generateExcell = () => {
    const newStructure = report.map((e, i) => {
      return {
        Escolheu: e.chooser.name,
        "Email do escolhedor": e.chooser.email,
        "Id do escolhedor": e.chooser.id,
        "Grupo do escolhedor": e.chooser.group || '',
        Escolhido: e.chosen.name,
        "Email do escolhido": e.chosen.email,
        "Id do escolhido": e.chosen.id,
        "Grupo do escolhido": e.chosen.group || '',
        "Data de envio da solicitação": formatData(e.createdAt),
        "Data de confirmação": e.accepted ? formatData(e.updateAt) : "-",
        Status: e.accepted ? "Concluido" : "Pendente",
      };
    });
    const fileName = `relatório-${new Date().getDate()}-${
      new Date().getMonth() + 1
    }-${new Date().getFullYear()}-${new Date().getHours()}:${new Date().getMinutes()}`;
    const exportType = "xls";
    exportFromJSON({ data: newStructure, fileName, exportType });
  };

  function getEventId() {
    if (window.location.search != "") {
      var user = window.location.search;

      var data = user.split("?");

      var id = base64.decode(data[1].replace("eventId=", ""));

      setEventId(Number(id));

      setNextFunc(true);
    }
  }

  async function getAllReport() {
    await getReport({
      eventId: eventId,
      // eventId: 67710,
      // eventId: 87491,
    })
      .then((response) => {
        setReport(response.data);
        console.log(response);
      })
      .catch((error) => console.log("function error", error));
  }

  function formatData(startDate) {
    //data
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();

    //hora
    var fullHour = startDate.split("T");
    var hour = fullHour[1].split(".");
    var dataAtual = dia + "/" + mes + "/" + ano + " - " + hour[0].slice(0, 5);

    return dataAtual;
  }

  function formatHour(date) {
    var fullHour = date.split("T");

    var hour = fullHour[1].split(".");
  }

  async function undoMatch() {
    await deleteMatch({
      id: selectedToDelete?.id,
    })
      .then((response) => {
        setOnAlertSuccess(true)
      })
      .catch((error) => console.log("function error", error));
  }

  useEffect(() => {
    getEventId();
  }, []);

  useEffect(() => {
    if (nextFunc) {
      getAllReport();
    }
  }, [nextFunc]);

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0 0 0",
      }}
    >
      <Col md={10} style={{ margin: 0, padding: 0 }}>
        <Card>
          <CardBody>
            <CardTitle>{`Evento: ${report[0]?.eventName}`}</CardTitle>
            <Row>
              <Col md={6}>
                <div className="app-search d-lg-block events-input-search">
                  <label className="form-label">Pesquise relatório</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Pesquise"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div>
                <Button
                  style={{ marginBottom: 10 }}
                  className="btn-success"
                  onClick={() => generateExcell()}
                >
                  Baixar Relatório
                </Button>
              </div>
            </Row>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Escolheu</th>
                    <th>Id</th>
                    <th>Escolhido</th>
                    <th>Id</th>
                    <th>Data de envio da solicitação</th>
                    <th>Data de confirmação</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {report.length > 0 &&
                    report
                      .filter(
                        (e) =>
                          e.chooser.name
                            .toLocaleLowerCase()
                            .indexOf(search.toLocaleLowerCase()) > -1
                      )
                      .map((e, i) => (
                        <tr key={i}>
                          <td onClick={() => console.log(e)}>
                            {e.chooser.name} - {e.chooser.email} {e?.chooser?.group ? ` - ${e.chooser.group}` : ''}
                          </td>
                          <td>{e.chooser.id}</td>
                          <td>
                            {e.chosen.name} - {e.chosen.email} {e?.chosen?.group ? ` - ${e.chosen.group}` : ''}
                          </td>
                          <td>{e.chosen.id}</td>
                          <td onClick={() => formatHour(e.createdAt)}>
                            {formatData(e.createdAt)}
                          </td>
                          <td>{e.accepted ? formatData(e.updateAt) : "-"}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                marginTop: 5,
                                width: 20,
                                height: 20,
                                borderRadius: 40,
                                backgroundColor: e.accepted
                                  ? "green"
                                  : "#ffd700",
                              }}
                            />
                          </td>
                          <td style={{
                            width: 30,
                            textAlign: 'center'

                          }}>
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedToDelete(e);
                                setOnAlert(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
      {onAlert && (
        <SweetAlert
          title={`Deseja excluir o registro?`}
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            await undoMatch(selectedToDelete)
            setOnAlert(false)
            setSelectedToDelete("");
          }}
          onCancel={() => {
            setOnAlert(false)
            setSelectedToDelete("");
          }}
        >
          {`Escolheu: ${selectedToDelete.chooser.email}  Escolhido: ${selectedToDelete.chosen.email}`}
        </SweetAlert>
      )}
      {onAlertSuccess && (
        <SweetAlert
          title={`Match excluído com sucesso!`}
          success
          showConfirm
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            getAllReport();
            setOnAlertSuccess(false)
          }}
        >
        </SweetAlert>
      )}

    </Row>
  );
}
