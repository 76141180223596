import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptBrTranslate from './translations/ptBr.json';
import enTranslate from './translations/en.json';
import esTranslate from './translations/es.json';

i18n.use(initReactI18next).init({
  fallbackLng: "ptBr",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: enTranslate,
    sp: esTranslate,
    ptBr: ptBrTranslate,
  }
})